import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Brand from "./../../assets/navbar/logo-brand.svg";
import "./navbar.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NavbarComponent = () => {
  const [stickyClass, setStickyClass] = useState("relative");
  const [activeSection, setActiveSection] = useState("home");
  const sections = useRef([]);
  const notify = () => toast("App will be Available Soon!");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setStickyClass("sticky-top")
        : setStickyClass("relative");
    }
  };

  const handleScroll = () => {
    const pageYOffset = window.scrollY;
    let newActiveSection = null;

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };

  useEffect(() => {
    sections.current = document.querySelectorAll("[data-section]");

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar navbar-expand-lg ${stickyClass}`}>
      <div className="container">
        <a className="navbar-brand" href="/">
          <img className="brand-img" src={Brand} alt="Brand Logo"></img>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav mx-auto my-2 my-lg-0 navbar-nav-scroll">
            <li
              className={
                activeSection === "home" ? "active nav-item" : "nav-item"
              }
            >
              <a className="nav-link" aria-current="page" href="#home">
                Home
              </a>
            </li>
            <li
              className={
                activeSection === "about" ? "active nav-item" : "nav-item"
              }
            >
              <a className="nav-link" href="#about">
                About Us
              </a>
            </li>
            <li
              className={
                activeSection === "service" ? "active nav-item" : "nav-item"
              }
            >
              <a className="nav-link" href="#service">
                Services
              </a>
            </li>
          
            <li
              className={
                activeSection === "contact" ? "active nav-item" : "nav-item"
              }
            >
              <a className="nav-link" href="#contact">
                Contact Us
              </a>
            </li>
          </ul>
          {/* <div className=" ml-auto">
            <button className="navbar-button" onClick={notify}>
              <ToastContainer />
              Install app
            </button>
          </div> */}
        </div>
      </div>
    </nav>
  );
};
export default NavbarComponent;

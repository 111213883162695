import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PiFacebookLogo } from "react-icons/pi";
import { PiInstagramLogo } from "react-icons/pi";
import { PiTwitterLogo } from "react-icons/pi";
import Brand from "./../../assets/navbar/logo-brand.svg";
import Map from "./../../assets/foooter/map.svg";
import "./footer.css";

const FooterComponent = () => {
  return (
    <section id="footer">
      <Container fluid>
        {/* <Row className="icon-text-container justify-content-center center">
          <Col className="border-app" lg={4}>
            <a
              className="social-media-icon-link"
              // href="https://www.facebook.com/profile.php?id=61559655362529"
            >
              <PiFacebookLogo className="icons" />
              <span className="text">Facebook</span>
            </a>
          </Col>
          <Col className="border-app" lg={4}>
            <a
              className="social-media-icon-link"
              // href="https://www.instagram.com/winscore.edutech_private.ltd"
            >
              <PiInstagramLogo className="icons" />
              <span className="text">Instagram</span>
            </a>
          </Col>
          <Col lg={4}>
            <PiTwitterLogo className="icons" />
            <span className="text">Twitter</span>
          </Col>
        </Row> */}
        <Container>
          <Row className="footer-link-map-container">
            <Col lg={4} className="footer-brand-container center mb-4">
              <img src={Brand} alt="" className="footer-brand img-fluid mb-3" />
              <span className="company-name">
                WINSCORE EDUTECH PRIVATE LIMITED
              </span>
              <address className="company-address">
                Building no: 32/203, Chairman
                Road, Thrikkandiyur, Tirur, Malappuram, <br></br>Kerala-676101
              </address>
              <div className="phone-mail-div">
                <div className="contact-container">
                  <span className="contact-label-footer">Contact us :</span>
                  <a className="details" href="tel:+918590683319">
                  +918590683319
                  </a>
                </div>
                <div className="contact-container">
                  <span className="contact-label-footer">Email id :</span>
                  <a className="details" href="mailto:winscoreedutech@gmail.com">
                  winscoreedutech@gmail.com
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={2} className="justify-content-center center mb-4">
              <div className="foot-link">
                <h5 href="/" className="foot-nav-head">
                  WINSCORE
                </h5>
                <div className="footer-links">
                  <a href="/#home" className="foot-nav-links">
                    Home
                  </a>
                  <a href="/#about" className="foot-nav-links">
                    About
                  </a>
                  <a href="/#service" className="foot-nav-links">
                    Services
                  </a>
                  <a href="/#contact" className="foot-nav-links">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-content-center center mb-4">
              <div className="foot-link">
                <h5 href="/" className="foot-nav-head">
                  Help & Support
                </h5>
                <div className="footer-links">
                  <a href="/privacy" className="foot-nav-links">
                    Privacy Policy
                  </a>
                  <a href="/terms" className="foot-nav-links">
                    Terms and Conditions
                  </a>
                  <a href="/cancellation" className="foot-nav-links">
                    Refund & Cancellation Policy
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-content-center center">
                <iframe
                className="map-img"
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3917.7395853641697!2d75.927885!3d10.907385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDU0JzI2LjYiTiA3NcKwNTUnNDAuNCJF!5e0!3m2!1sen!2sin!4v1718080351472!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-center center">
              <p className="footer-span">
                Powered by
                <a className="footer-span-link" href="https://www.nthindex.com">
                  Nthindex Software Solutions LLP
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};
export default FooterComponent;

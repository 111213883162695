import React from "react";
import { Container } from "react-bootstrap";

const CancellationPolicy = () => {
  return (
    <section style={{ textAlign: "start" }} id="cancellationpolicy">
      <Container>
        <h1 className="heading my-5" style={{ textAlign: "center" }}>
          Refund & Cancellation Policy
        </h1>
        <p className="para">
          This is a non-refundable product as the users can download it
          immediately after the payment. So, no refund will be issued once the
          product is purchased. We strongly recommend to go through the demo
          samples provided in the Official YoutubeChannel before buying the
          subscription.
          <a href="https://www.youtube.com/@IQWin-edu"></a>
        </p>
      </Container>
    </section>
  );
};
export default CancellationPolicy;

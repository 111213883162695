import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import user from "./../../assets/download/user-icon.svg";
import time from "./../../assets/download/time.svg";
import navigate from "./../../assets/download/navigate.svg";
import download from "./../../assets/download/download.svg";
import mobiles from "./../../assets/download/mobile.png";
import book from "./../../assets/download/book.svg";
import { FaApple } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import contactImg from "./../../assets/download/contact-img.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import "./download.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import axios from "../../network/api.js";
import { baseUrl } from "../../network/constants/constants.js";
import playstore from "../../assets/download/playstore.svg";
import appstore from "../../assets/download/apple.svg";
import gratuate from "../../assets/download/gratuate.png"

const DownloadComponent = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const handleSubmitMessage = () => {
    const data = { name, phone, message };
    submitMail(data);
  };

  const submitMail = (data) => {
    setLoading(true);
    const payload = {
      name: data.name,
      phone: data.phone,
      message: data.message,
    };

    axios
      .post(baseUrl + "app/ContactUs/", payload)
      .then((response) => {
        notify();
        setName("");
        setPhone("");
        setMessage("");
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error submitting the form: ", error);
        setLoading(false);
      });
  };

  const clearInputError = (fieldName) => {
    clearErrors(fieldName);
  };

  const notify = () => toast.success("Thank you for contacting us!");

  return (
    <div className="contact-background">
      <ToastContainer />
      <section id="download">
        <Container>
          <div className="download-container">
            <Row>
              <Col className="justify-content-center center">
                <h5 className="download-heading">"Get Our App Now!"</h5>
                <p className="download-para">
                  Our app is now available on both
                  <span className="download-span"> Google Play & Appstore</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="justify-content-center center">
                <div className="download-right-div">
                  <div className="featuters">
                    <div className="icon-content">
                      <img src={user} alt="" className="icon" />
                      <p className="contents">
                        Interactive lessons for engaging learning experiences.
                      </p>
                    </div>
                    <div className="icon-content">
                      <img src={time} alt="" className="icon" />
                      <p className="contents">Learn at your own pace.</p>
                    </div>
                    <div className="icon-content">
                      <img src={navigate} alt="" className="icon" />
                      <p className="contents">
                        Seamless and user-friendly interface for easy
                        navigation.
                      </p>
                    </div>
                    <div className="icon-content">
                      <img src={download} alt="" className="icon" />
                      <p className="contents">
                      Download now to revolutionize your education journey!
                      </p>
                    </div>
                  </div>
                  {/* <div className="download-btn-container">
                    <div className="download-head">DOWNLOAD NOW</div>
                    <div className="download-buttons">
                      <button className="appstore-btn">
                        App store <img src={appstore} alt="" className="store-one" />
                      </button>
                      <button className="playstore-btn">
                        Playstore <img src={playstore} alt="" className="store-two"/>
                      </button>
                    </div>
                  </div> */}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mobiles-container">
                  <img className="mobile-img" src={mobiles} alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* contact */}
      <section id="contact" data-section>
        <Container>
          <div className="contact-container">
            <Row>
              <Col lg={5} className="justify-content-center center">
              <img src={gratuate} alt="" className="gratuate" />
                <img src={contactImg} alt="" className="contact-image" />
              </Col>
              <Col lg={7} className="justify-content-center center">
                <div className="contact-form-container">
                  <p className="contact-p">Feel free to contact us</p>
                  <h5 className="contact-head">Connect with us</h5>
                  <p className="contact-content">
                    "Reach out to us for top-notch educational solutions
                    tailored to your needs. Contact us today to start your
                    learning journey!"
                  </p>
                  <div className="contact-form">
                    <div className="contact-input-field">
                      <label htmlFor="name" className="contact-label">
                        Name*
                      </label>
                      <input
                        id="name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        autoComplete="off"
                        className={`contact-fields name-field ${
                          errors.name && "invalid"
                        }`}
                        {...register("name", {
                          required: "Name is Required",
                        })}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          clearInputError("name");
                        }}
                      />
                      {errors.name && (
                        <small className="error-text">
                          {errors.name.message}
                        </small>
                      )}
                    </div>
                    <div className="contact-input-field">
                      <label htmlFor="phone" className="contact-label">
                        Phone number*
                      </label>
                      <input
                        id="phone"
                        type="tel"
                        placeholder="Phone number"
                        className={`contact-fields number-field ${
                          errors.phone && "invalid"
                        }`}
                        {...register("phone", {
                          required: "Phone is Required !",
                          pattern: {
                            value:
                              /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                            message: "Invalid phone number!",
                          },
                        })}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          clearInputError("phone");
                        }}
                      />
                      {errors.phone && (
                        <small className="error-text">
                          {errors.phone.message}
                        </small>
                      )}
                    </div>
                    <div className="contact-input-field">
                      <div className="message-labels">
                        <label htmlFor="message" className="contact-label">
                          Message
                        </label>
                        <label htmlFor="message" className="contact-label">
                          Max 500 characters
                        </label>
                      </div>
                      <textarea
                        id="message"
                        type="text"
                        name="message"
                        rows="4"
                        cols="50"
                        placeholder="Message"
                        className={`contact-fields msq-field ${
                          errors.message && "invalid"
                        }`}
                        {...register("message", {
                          required: "Message is Required !",
                        })}
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          clearInputError("message");
                        }}
                      />
                      {errors.message && (
                        <small className="error-text">
                          {errors.message.message}
                        </small>
                      )}
                    </div>
                    <div className="c-btn">
                      <button
                        className="contact-btn"
                        onClick={handleSubmit(handleSubmitMessage)}
                      >
                        Submit
                        <FaArrowRightLong className="arrow-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default DownloadComponent;


// import React, { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import user from "./../../assets/download/user-icon.svg";
// import time from "./../../assets/download/time.svg";
// import navigate from "./../../assets/download/navigate.svg";
// import download from "./../../assets/download/download.svg";
// import mobiles from "./../../assets/download/mobile.png";
// import book from "./../../assets/download/book.svg";
// import { FaApple } from "react-icons/fa";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import contactImg from "./../../assets/download/contact-img.svg";
// import { FaArrowRightLong } from "react-icons/fa6";
// import "./download.css";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useForm } from "react-hook-form";
// import axios from "../../network/api.js";
// import { baseUrl } from "../../network/constants/constants.js";
// import playstore from "../../assets/download/playstore.svg";
// import appstore from "../../assets/download/apple.svg"
// const DownloadComponent = () => {
//   // const [name, setName] = useState("");
//   // const [phone, setPhone] = useState("");
//   // const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(false);

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//     setError,
//     clearErrors,
//   } = useForm();

//   const handleSubmitMessage = (data) => {
//     submitMail(data);
//   };

//   const submitMail = (data) => {
//     setLoading(true);
//     const payload = {
//       name: data.name,
//       phone: data.phone,
//       message: data.message,
//     };

//     axios
//       .post(baseUrl + "app/ContactUs/", payload)
//       .then((response) => {
//         notify();
//         reset();
//         setLoading(false);
//         console.log(response.data);
//       })
//       .catch((error) => {
//         console.error("Error submitting the form: ", error);
//         setLoading(false);
//       });
//   };

 
//   const clearInputError = (fieldName) => {
//     clearErrors(fieldName);
//   };

//   const notify = () => toast.success("Thank you for contacting us!");

//   return (
//     <div className="contact-background">
//       <ToastContainer />
//       <section id="download">
//         <Container>
//           <div className="download-container">
//             <Row>
//               <Col className="justify-content-center center">
//                 <h5 className="download-heading">"Get Our App Now!"</h5>
//                 <p className="download-para">
//                   Our app is now available on both
//                   <span className="download-span"> Google Play & Appstore</span>
//                 </p>
//               </Col>
//             </Row>
//             <Row>
//               <Col lg={6} className="justify-content-center center">
//                 <div className="download-right-div">
//                   <div className="featuters">
//                     <div className="icon-content">
//                       <img src={user} alt="" className="icon" />
//                       <p className="contents">
//                         Interactive lessons for engaging learning experiences.
//                       </p>
//                     </div>
//                     <div className="icon-content">
//                       <img src={time} alt="" className="icon" />
//                       <p className="contents">Learn at your own pace.</p>
//                     </div>
//                     <div className="icon-content">
//                       <img src={navigate} alt="" className="icon" />
//                       <p className="contents">
//                         Seamless and user-friendly interface for easy
//                         navigation.
//                       </p>
//                     </div>
//                     <div className="icon-content">
//                       <img src={book} alt="" className="icon" />
//                       <p className="contents">
//                         Revolutionize your education journey!
//                       </p>
//                     </div>
//                   </div>
//                   <div className="download-btn-container">
//                   <div className="download-head">DOWNLOAD NOW</div>
//                   <div className="download-buttons">
//                     <button className="appstore-btn">App store <img src={appstore} alt="" /></button>
//                     <button className="playstore-btn">Playstore <img src={playstore} alt="" /></button>
//                   </div>
//                 </div>
//                 </div>
              
//               </Col>
//               <Col lg={6}>
//                 <div className="mobiles-container">
//                   <img className="mobile-img" src={mobiles} alt="" />
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//       </section>
//       {/* contact */}

//       <section id="contact" data-section>
//         <Container>
//           <div className="contact-container">
//             <Row>
//               <Col lg={5} className="justify-content-center center">
//                 <img src={contactImg} alt="" className="contact-image" />
//               </Col>
//               <Col lg={7} className="justify-content-center center">
//                 <div className="contact-form-container">
//                   <p className="contact-p">Feel free to contact us</p>
//                   <h5 className="contact-head">Connect with us</h5>
//                   <p className="contact-content">
//                     "Reach out to us for top-notch educational solutions
//                     tailored to your needs. Contact us today to start your
//                     learning journey!"
//                   </p>
//                   <div className="contact-form">
//                     <div className="contact-input-field">
//                       <label htmlFor="Name *" className="contact-label">
//                         Name*
//                       </label>
//                       <input
//                         id="name"
//                         type="text"
//                         placeholder="Name"
//                         name="name"
//                         autoComplete="off"
//                         className={`contact-fields name-field ${
//                           errors.name && "invalid"
//                         }`}
//                         {...register("name", {
//                           required: "Name is Required",
//                         })}
//                         onChange={(e) => {
//                           clearInputError("name");
//                         }}
//                       />
//                       {errors.name && (
//                         <small className="error-text">
//                           {errors.name.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="contact-input-field">
//                       <label htmlFor="Phone *" className="contact-label">
//                         Phone number*
//                       </label>
//                       <input
//                         id="phone"
//                         type="tel"
//                         placeholder="Phone number"
//                         className={`contact-fields number-field ${
//                           errors.phone && "invalid"
//                         }`}
//                         {...register("phone", {
//                           required: "Phone is Required !",
//                           pattern: {
//                             value:
//                               /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
//                             message: "Invalid phone number!",
//                           },
//                         })}
//                         onChange={(e) => {
//                           clearInputError("phone");
//                         }}
//                       />
//                       {errors.phone && (
//                         <small className="error-text">
//                           {errors.phone.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="contact-input-field">
//                       <div className="message-labels">
//                         <label htmlFor="Message" className="contact-label">
//                           Message
//                         </label>
//                         <label htmlFor="Message" className="contact-label">
//                           Max 500 characters
//                         </label>
//                       </div>
//                       <textarea
//                         id="message"
//                         type="text"
//                         name="message"
//                         rows="4"
//                         cols="50"
//                         placeholder="Message"
//                         className={`contact-fields msq-field ${
//                           errors.message && "invalid"
//                         }`}
//                         {...register("message", {
//                           required: "Message is Required !",
//                         })}
//                         onChange={(e) => {
//                           clearInputError("message");
//                         }}
//                       />
//                       {errors.message && (
//                         <small className="error-text">
//                           {errors.message.message}
//                         </small>
//                       )}
//                     </div>
//                     <div className="c-btn">
//                       <button
//                         className="contact-btn"
//                         onClick={handleSubmit(handleSubmitMessage)}
//                       >
//                         Submit
//                         <FaArrowRightLong className="arrow-icon" />
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//       </section>
//     </div>
//   );
// };

// export default DownloadComponent;
